/**
version=0.17
如何设置页面逻辑，请查阅wide手册: http://www.wware.org/wide/indexw3c.html
**/

(function() {

  function custom_init($) {
    $("#addr").click(function(){ //lable标签的id
			document.getElementById("youxiang").focus();//文本框的id
		});
    $("#pass").click(function(){ //lable标签的id
			document.getElementById("mima").focus();//文本框的id
		});
  }

  /*@brief 事件逻辑允许直接兼容JQuery风格，这里可以直接按照Jquery的代码处理风格来监听事件并做出处理。
  @notice: 1.如果需要访问绑定变量，必须使用$.wwclass.vm("viewModel")这种形式。
  2. 可以直接使用jquery语法更新控制变量(data--XXX)，对应的绑定变量自动更新，无需手动刷新绑定变量。
  3. 使用鼠标右纽在下方函数内单击，可以添加模板代码。
  //*/
  function event_init($) { //事件逻辑定义
    /*INSBEGIN:EVENTINIT*/

    /*INSEND:EVENTINIT*/
  } // 事件逻辑定义结束

  /*@brief 允许您定义一个计算类型的绑定变量，此变量的值由依赖的其它值动态计算得到。
  @notice: 1.一旦这里定义，请不要在其它地方(绑定变量初始化、服务器返回)来重新定义。
  2. 这里的定义会覆盖绑定变量初始化的定义，但是会被服务器返回的内容覆盖。
  3. 代码中可以使用其它绑定变量，如果其它绑定变量被刷新，这个变量的值自动刷新。
  4. 在使用上，计算型绑定变量与其它类型的绑定变量没有任何区别。
  5. 使用鼠标右纽在下方函数内单击，可以添加模板代码。
  //*/
  function binding_init($) { // 复合信息点定义
    return /*INSBEGIN:COMPUTED*/ {

    } /*INSEND:COMPUTED*/ ;
  } // 复合信息点定义结束

  /*@brief 允许您监测任意绑定变量(包括计算型变量)的变化，一旦值发生变化，自动调用相应处理函数。
  @notice: 1.变量的事件回调是同步的，如果需要，请自行使用defer将其切换为异步。
  2. 使用鼠标右纽在下方函数内单击，可以添加模板代码。
  //*/
  function subscribe_init($) { // 信息点变化定义
    return /*INSBEGIN:SUBSCRIBE*/ {

    } /*INSEND:SUBSCRIBE*/ ;
  } // 信息点变化定义结束

  /*@brief 命名函数是扩展任意元素的逻辑-逻辑里的绑定数据编程的，在绑定数据编程的参数处，可以直接输入任意存在的命名函数名称。
  其作用、函数参数与就地编辑的绑定数据编程完全一致。
  @notice: 1.命名函数的执行效率高于就地代码。
  2. 如果代码超过5行，建议使用命名函数。
  3. 使用鼠标右纽在下方函数内单击，可以添加模板代码。
  //*/
  function named_functions($) { // 命名函数定义
    return /*INSBEGIN:NAMEDFUNC*/ {

    } /*INSEND:NAMEDFUNC*/ ;
  } // 命名函数定义结束


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 下方代码自动生成，如不清晰，请勿修改
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  requirejs.config(
    /*INSPOINTBEGIN:REQUIREJS_CFG*/
    {
      "baseUrl": "/",
      "paths": {
        "jquery": "libs/jquery/dist/jquery.min",
        "j3migrate" : "libs/jquery-migrate/jquery-migrate-3.0.0.min",
        "css": "libs/require-css/css.min",
        "image": "libs/requirejs-plugins/src/image",
        "bootstrap": "libs/bootstrap/dist/js/bootstrap.min"
      },
      "shim": {
        "bootstrap": {
          "deps": ["jquery"]
        },
        "j3migrate" : {
          "deps": ["jquery"]
        }
      }
    }
    /*INSPOINTEND:REQUIREJS_CFG*/
  );

  require(["jquery", "bootstrap", "libs/wware/wwclass.min","j3migrate"],
    function($, bootstrap, wwclass) {

      window.jQuery = window.$ = $;

      $.wwPageconfig = /*INSPOINTEBEGIN:PAGECONFIG*/ {} /*INSPOINTEEND:PAGECONFIG*/ ;

      $().ready(function() {

        if (!$.wwclass) {
          wwclass.init($);
          $.wwcbi = binding_init($);
          $.wwnf = named_functions($);
          $.wwst = subscribe_init($);
        }

        $.wwclass.process($("body"));

        event_init($);
        custom_init($);
      });
    });
})();
